export default {
  followSale: {
    id: 'buyback_my_sales_follow_my_sales',
    defaultMessage: 'Follow my sale',
  },
  shipItem: {
    id: 'buyback_my_sales_ship_item',
    defaultMessage: 'Ship item',
  },
  salesInfoTitle: {
    id: 'buyback_my_sales_details_sale_info_title',
    defaultMessage: 'Sale info',
  },
  price: {
    id: 'buyback_my_sales_details_price',
    defaultMessage: 'Price',
  },
  orderId: {
    id: 'buyback_my_sales_details_order_id',
    defaultMessage: 'Order ID',
  },
  refurbisher: {
    id: 'buyback_my_sales_details_refurbisher',
    defaultMessage: 'Refurbisher',
  },
  assessmentTitle: {
    id: 'buyback_my_sales_details_assessment_title',
    defaultMessage: 'Assessment',
  },
  documentsTitle: {
    id: 'buyback_my_sales_documents_title',
    defaultMessage: 'Documents',
  },
  shippingLabel: {
    id: 'buyback_my_sales_shipping_label',
    defaultMessage: 'Shipping label',
  },
  saleVoucher: {
    id: 'buyback_my_sales_sale_voucher',
    defaultMessage: 'Sale voucher',
  },
  shippingInfoTitle: {
    id: 'buyback_my_sales_shipping_info_title',
    defaultMessage: 'Shipping info',
  },
  shippingMode: {
    id: 'buyback_my_sales_shipping_mode',
    defaultMessage: 'Shipping mode',
  },
  carrier: {
    id: 'buyback_my_sales_carrier',
    defaultMessage: 'Carrier',
  },
  parcelNumber: {
    id: 'buyback_my_sales_parcel_number',
    defaultMessage: 'Parcel number',
  },
  from: {
    id: 'buyback_my_sales_address_from',
    defaultMessage: 'From',
  },
  to: {
    id: 'buyback_my_sales_address_to',
    defaultMessage: 'To',
  },
  toastCounterOfferErrorTitle: {
    id: 'buyback_my_sales_counteroffer_modal_toast_error_title',
    defaultMessage: 'Doh!',
  },
  toastCounterOfferAcceptTitle: {
    id: 'buyback_my_sales_counteroffer_modal_toast_accept_title',
    defaultMessage: 'Counteroffer accepted!',
  },
  toastCounterOfferRejectTitle: {
    id: 'buyback_my_sales_counteroffer_modal_toast_reject_title',
    defaultMessage: 'Sweet, sweet rejection',
  },
  toastCounterOfferErrorContent: {
    id: 'buyback_my_sales_counteroffer_modal_toast_error_content',
    defaultMessage: 'There was a technical glitch. Try again.',
  },
  toastCounterOfferAcceptContent: {
    id: 'buyback_my_sales_counteroffer_modal_toast_accept_content',
    defaultMessage:
      'You’ll see money hit your bank account within 6 business days.',
  },
  toastCounterOfferRejectContent: {
    id: 'buyback_my_sales_counteroffer_modal_toast_reject_content',
    defaultMessage:
      'The refurbisher will ship your item back to you within 2 business days.',
  },
  toastClose: {
    id: 'buyback_my_sales_ship_item_shipping_step_toast_close',
    defaultMessage: 'Close',
  },
}
